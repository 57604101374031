import Cookies from 'js-cookie';
import CacheEnabledApiClient from '../CacheEnabledApiClient';

/* global axios */
class TemplatesUbluxAPI extends CacheEnabledApiClient {
  constructor() {
    super('templates', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'template';
  }

  // eslint-disable-next-line class-methods-use-this
  get url() {
    return process.env.VUE_APP_API_WA;
  }

  // eslint-disable-next-line class-methods-use-this
  get token() {
    const sessionInfo = Cookies.get('cw_d_session_info');
    const sessionInfoObject = JSON.parse(sessionInfo);

    return sessionInfoObject.authorization;
  }

  // eslint-disable-next-line class-methods-use-this
  get httpOptions() {
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.token,
      },
    };
  }

  getTimeLeft(inbox_id, contact_wa_id) {
    return axios.chatwoot.get(
      `${this.url}/was/ublux/v2/wa-conversations/time-left/${inbox_id}/${contact_wa_id}`,
      this.httpOptions
    );
  }

  getTemplates(fromPhoneNumber) {
    let variable = '';
    if (fromPhoneNumber) {
      variable = `/${fromPhoneNumber}`;
    }

    return axios.chatwoot.get(
      `${this.url}/was/ublux/v2/was-templates/get-all${variable}`,
      this.httpOptions
    );
  }

  synchronize() {
    return axios.chatwoot.get(
      `${this.url}/was/ublux/v2/was-templates/sync-templates`,
      this.httpOptions
    );
  }

  postMedia(fileData, type) {
    return axios.chatwoot.post(`${this.url}/was/ublux/v2/media`, fileData, {
      headers: {
        Authorization: this.token,
        'Content-Type': type,
      },
    });
  }

  submit(payload, fromPhoneNumber) {
    return axios.chatwoot.post(
      `${this.url}/was/cloud-api/outbound/v3/send/${fromPhoneNumber}`,
      payload,
      this.httpOptions
    );
  }

  // eslint-disable-next-line class-methods-use-this
  create(payload, wabaAccountId) {
    return axios.ublux.post(
      `https://wa-us-1.ublux.com/was/d360/waba/template/${wabaAccountId}`,
      payload
    );
  }

  fetchDataMedia(payload, phones) {
    return this.postMedia(
      payload.templateMedia.fileArrayBuffer,
      payload.templateMedia.type
    ).then(firstResponse => {
      const headerComponent = payload.templateJson.template.components.find(
        component => component.type === 'header'
      );
      headerComponent.parameters = [
        {
          type: payload.templateMedia.format,
          [payload.templateMedia.format]: {
            id: firstResponse.data.media[0].id,
          },
        },
      ];
      const template = {
        ...payload.templateJson,
        to: phones.to,
      };
      return this.submit(template, phones.from);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  delete(wabaAccountId, templateId) {
    return axios.ublux.delete(
      `https://wa-us-1.ublux.com/was/d360/waba/template/${wabaAccountId}/${templateId}`
    );
  }
}

export default new TemplatesUbluxAPI();
