/* global axios */
import ApiClient from './ApiClient';

class ConversationApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  getLabels(conversationID) {
    return axios.chatwoot.get(`${this.url}/${conversationID}/labels`);
  }

  updateLabels(conversationID, labels) {
    return axios.chatwoot.post(`${this.url}/${conversationID}/labels`, {
      labels,
    });
  }
}

export default new ConversationApi();
