import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../../mutation-types';
import PhoneNumbersFromUbluxAPI from '../../../api/ublux/phoneNumbersFromUblux';
// import AnalyticsHelper from '../../helper/AnalyticsHelper';
// import { PHONE_NUMBERS_FROM_EVENTS } from '../../helper/AnalyticsHelper/events';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getPhoneNumbersFrom: _state => {
    return _state.records;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.SET_PHONE_NUMBERS_FROM_UI_FLAG, { isFetching: true });
    try {
      const response = await PhoneNumbersFromUbluxAPI.getWasPhoneNumbers(true);
      commit(types.SET_PHONE_NUMBERS_FROM, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_PHONE_NUMBERS_FROM_UI_FLAG, { isFetching: false });
    }
  },
  // create: async function createPhoneNumberFrom({ commit }, phoneObj) {
  //   commit(types.SET_PHONE_NUMBERS_FROM_UI_FLAG, { isCreating: true });
  //   try {
  //     const response = await PhoneNumbersFromUbluxAPI.create(phoneObj);
  //     commit(types.ADD_PHONE_NUMBERS_FROM, response.data);
  //   } catch (error) {
  //     throw new Error(error);
  //   } finally {
  //     commit(types.SET_PHONE_NUMBERS_FROM_UI_FLAG, { isCreating: false });
  //   }
  // },
  // update: async ({ commit }, { id, ...updateObj }) => {
  //   commit(types.SET_PHONE_NUMBERS_FROM_UI_FLAG, { isUpdating: true });
  //   try {
  //     const response = await PhoneNumbersFromUbluxAPI.update(id, updateObj);
  //     AnalyticsHelper.track(PHONE_NUMBERS_FROM_EVENTS.UPDATE_PHONE_NUMBERS_FROM);
  //     commit(types.EDIT_PHONE_NUMBERS_FROM, response.data);
  //   } catch (error) {
  //     throw new Error(error);
  //   } finally {
  //     commit(types.SET_PHONE_NUMBERS_FROM_UI_FLAG, { isUpdating: false });
  //   }
  // },
  // delete: async ({ commit }, id) => {
  //   commit(types.SET_PHONE_NUMBERS_FROM_UI_FLAG, { isDeleting: true });
  //   try {
  //     await PhoneNumbersFromUbluxAPI.delete(id);
  //     AnalyticsHelper.track(PHONE_NUMBERS_FROM_EVENTS.DELETE_PHONE_NUMBERS_FROM);
  //     commit(types.DELETE_PHONE_NUMBERS_FROM, id);
  //   } catch (error) {
  //     throw new Error(error);
  //   } finally {
  //     commit(types.SET_PHONE_NUMBERS_FROM_UI_FLAG, { isDeleting: false });
  //   }
  // },
};

export const mutations = {
  [types.SET_PHONE_NUMBERS_FROM_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.ADD_PHONE_NUMBERS_FROM]: MutationHelpers.create,
  [types.SET_PHONE_NUMBERS_FROM]: MutationHelpers.set,
  [types.EDIT_PHONE_NUMBERS_FROM]: MutationHelpers.update,
  [types.DELETE_PHONE_NUMBERS_FROM]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
