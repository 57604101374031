import { frontendURL } from '../../../../helper/URLHelper';
const SettingsContent = () => import('../Wrapper.vue');
const TemplatesHome = () => import('./Index.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/templates'),
      component: SettingsContent,
      props: {
        headerTitle: 'TEMPLATES_SETTINGS.HEADER',
        headerButtonText: 'TEMPLATES_SETTINGS.NEW_TEMPLATE',
        icon: 'description',
        showNewButton: true,
      },
      children: [
        {
          path: '',
          name: 'templates_wrapper',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'templates_list',
          component: TemplatesHome,
          roles: ['administrator', 'agent'],
        },
      ],
    },
  ],
};
