export const FileType = {
  Image: 'image',
  Audio: 'audio',
  Document: 'document',
  Video: 'video',
  Sticker: 'sticker',
  Text: 'text',
};

export const ValidMimeTypes = {
  [FileType.Image]: {
    extensions: ['.png', '.jpg', '.jpeg'],
    mimeTypes: ['image/png', 'image/jpeg'],
  },
  [FileType.Audio]: {
    extensions: ['.mp4', '.mp3', '.amr', '.ogg', '.wav'],
    mimeTypes: [
      'audio/aac',
      'audio/mp4',
      'audio/mpeg',
      'audio/amr',
      'audio/ogg',
      'audio/wav',
    ],
  },
  [FileType.Document]: {
    extensions: [
      '.txt',
      '.pdf',
      '.xlx',
      '.xlsx',
      '.doc',
      '.docx',
      '.ppt',
      '.pptx',
    ],
    mimeTypes: [
      'text/plain',
      'application/pdf',
      'application/vnd.ms-powerpoint',
      'application/msword',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
  },
  [FileType.Video]: {
    extensions: ['.mp4', '.3gp', '.avi', 'mov'],
    mimeTypes: [
      'video/mp4',
      'video/3gpp',
      'video/x-msvideo',
      'video/quicktime',
    ],
  },
  [FileType.Sticker]: {
    extensions: [],
    mimeTypes: [],
  },
  [FileType.Text]: {
    extensions: [],
    mimeTypes: [],
  },
};
