import Cookies from 'js-cookie';
import CacheEnabledApiClient from '../CacheEnabledApiClient';

/* global axios */
class PhoneNumbersFromUbluxAPI extends CacheEnabledApiClient {
  constructor() {
    super('phoneNumbersFrom', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'phoneNumberFrom';
  }

  // eslint-disable-next-line class-methods-use-this
  get url() {
    return process.env.VUE_APP_API_WA;
  }

  // eslint-disable-next-line class-methods-use-this
  get token() {
    const sessionInfo = Cookies.get('cw_d_session_info');
    const sessionInfoObject = JSON.parse(sessionInfo);

    return sessionInfoObject.authorization;
  }

  // eslint-disable-next-line class-methods-use-this
  get httpOptions() {
    return {
      headers: {
        Authorization: this.token,
      },
    };
  }

  getWasPhoneNumbers() {
    return axios.chatwoot.get(
      `${this.url}/was/ublux/v2/was-phone-numbers/find-all`,
      this.httpOptions
    );
  }
}

export default new PhoneNumbersFromUbluxAPI();
