import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../../mutation-types';
import FlowsUbluxAPI from '../../../api/ublux/flowsUblux';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isUpdating: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getFlows: _state => {
    return _state.records;
  },
};

export const actions = {
  get: async ({ commit }, wa_id) => {
    commit(types.SET_FLOWS_UBLUX_UI_FLAG, { isFetching: true });
    try {
      if (wa_id) {
        const response = await FlowsUbluxAPI.getFlows(wa_id);
        commit(types.SET_FLOWS_UBLUX, response.data.flows);
      }
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_FLOWS_UBLUX_UI_FLAG, { isFetching: false });
    }
  },
};

export const mutations = {
  [types.SET_FLOWS_UBLUX_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_FLOWS_UBLUX]: MutationHelpers.set,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
