import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../../mutation-types';
import TemplatesUbluxAPI from '../../../api/ublux/templatesUblux';

export const state = {
  records: [],
  allRecords: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
  },
};

function handleError(error) {
  if (error.response && error.response.data && error.response.data.meta) {
    const errorMeta = error.response.data.meta;

    if (errorMeta.error) {
      let text = errorMeta.error;
      if (typeof errorMeta.error === 'string') {
        text = JSON.parse(errorMeta.error);
      }

      if (typeof text === 'string') {
        throw new Error(text);
      } else {
        const data = { ...text };
        throw new Error(data['error'].error_user_msg);
      }
    } else if (errorMeta.developer_message) {
      throw new Error(errorMeta.developer_message);
    } else {
      throw new Error(error);
    }
  } else {
    throw new Error(error);
  }
}

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getTemplates: _state => {
    return _state.records;
  },
  getAllTemplates: _state => {
    return _state.allRecords;
  },
};

export const actions = {
  get: async ({ commit, rootGetters }) => {
    commit(types.SET_TEMPLATES_UI_FLAG, { isFetching: true });
    try {
      const fromPhoneNumbers =
        await rootGetters['phoneNumbersFrom/getPhoneNumbersFrom'];
      const fetchPromises = fromPhoneNumbers.map(async phone => {
        const response = await TemplatesUbluxAPI.getTemplates(
          phone.phoneNumber
        );
        return {
          phoneNumber: phone.phoneNumber,
          templates: response.data.items.filter(
            item => item.status === 'approved'
          ),
        };
      });

      const result = await Promise.all(fetchPromises);

      const templatesByPhoneNumber = result.reduce(
        (acc, { phoneNumber, templates }) => {
          acc[phoneNumber.replace('+', '')] = templates;
          return acc;
        },
        {}
      );

      commit(types.SET_TEMPLATES, templatesByPhoneNumber);
    } catch (error) {
      handleError(error);
    } finally {
      commit(types.SET_TEMPLATES_UI_FLAG, { isFetching: false });
    }
  },
  getAll: async ({ commit }, fromPhoneNumber) => {
    commit(types.SET_TEMPLATES_UI_FLAG, { isFetching: true });
    try {
      const response = await TemplatesUbluxAPI.getTemplates(fromPhoneNumber);
      const templates = response.data.items;

      commit(types.SET_TEMPLATES_ALL, templates);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_TEMPLATES_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit }, templateObj) => {
    commit(types.SET_TEMPLATES_UI_FLAG, { isCreating: true });
    try {
      const response = await TemplatesUbluxAPI.create(
        templateObj.payload,
        templateObj.wabaAccountId
      );
      commit(types.ADD_TEMPLATES, {
        data: response.data,
        from: templateObj.from,
        filterFrom: templateObj.filterFrom,
      });
    } catch (error) {
      handleError(error);
    } finally {
      commit(types.SET_TEMPLATES_UI_FLAG, { isCreating: false });
    }
  },
  // update: async ({ commit }, { id, ...updateObj }) => {
  //   commit(types.SET_TEMPLATES_UI_FLAG, { isUpdating: true });
  //   try {
  //     const response = await TemplatesUbluxAPI.update(id, updateObj);
  //     AnalyticsHelper.track(TEMPLATES_EVENTS.UPDATE_TEMPLATES);
  //     commit(types.EDIT_TEMPLATES, response.data);
  //   } catch (error) {
  //     throw new Error(error);
  //   } finally {
  //     commit(types.SET_TEMPLATES_UI_FLAG, { isUpdating: false });
  //   }
  // },
  delete: async ({ commit }, { wabaAccountId, templateId, from }) => {
    commit(types.SET_TEMPLATES_UI_FLAG, { isDeleting: true });
    try {
      await TemplatesUbluxAPI.delete(wabaAccountId, templateId);
      commit(types.DELETE_TEMPLATES, { templateId, from });
    } catch (error) {
      handleError(error);
    } finally {
      commit(types.SET_TEMPLATES_UI_FLAG, { isDeleting: false });
    }
  },
  synchronize: async ({ commit, dispatch }, fromPhoneNumber) => {
    commit(types.SET_TEMPLATES_UI_FLAG, { isUpdating: true });
    try {
      await TemplatesUbluxAPI.synchronize();
      await dispatch('get');
      await dispatch('getAll', fromPhoneNumber);
    } catch (error) {
      handleError(error);
    } finally {
      commit(types.SET_TEMPLATES_UI_FLAG, { isUpdating: false });
    }
  },
};

export const mutations = {
  [types.SET_TEMPLATES_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.SET_TEMPLATES_ALL]($state, data) {
    $state.allRecords = data || [];
  },

  [types.ADD_TEMPLATES]($state, { data, from, filterFrom }) {
    $state.records[from.replace('+', '')].push(data);

    if (from.replace('+', '') === filterFrom) {
      $state.allRecords.push(data);
    }
  },
  [types.SET_TEMPLATES]: MutationHelpers.set,
  [types.DELETE_TEMPLATES]($state, { templateId, from }) {
    $state.records[from] = $state.records[from].filter(
      record => record.id !== templateId
    );
    $state.allRecords = $state.allRecords.filter(
      record => record.id !== templateId
    );
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
