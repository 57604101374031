import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../../mutation-types';
import PhonesUbluxAPI from '../../../api/ublux/phonesUblux';
import Cookies from 'js-cookie';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isUpdating: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getUserPhones: _state => {
    return _state.records;
  },
};

export const actions = {
  getFilterByUser: async ({ commit }) => {
    commit(types.SET_PHONES_UBLUX_UI_FLAG, { isFetching: true });
    try {
      const idUser = JSON.parse(Cookies.get('ublux_session_info')).userId;
      const response = await PhonesUbluxAPI.getFilterByUser(idUser);
      const phones =
        response.data.records.length > 0 ? response.data.records : [];
      commit(types.SET_PHONES_UBLUX, phones);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_PHONES_UBLUX_UI_FLAG, { isFetching: false });
    }
  },

  update: async ({ commit }, { id, ...phoneParams }) => {
    commit(types.SET_PHONES_UBLUX_UI_FLAG, { isUpdating: true });
    try {
      const response = await PhonesUbluxAPI.update(id, phoneParams);
      commit(types.EDIT_PHONE_UBLUX, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_PHONES_UBLUX_UI_FLAG, { isUpdating: false });
    }
  },
};

export const mutations = {
  [types.SET_PHONES_UBLUX_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_PHONES_UBLUX]: MutationHelpers.set,
  [types.EDIT_PHONE_UBLUX]: MutationHelpers.update,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
