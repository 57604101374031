import CacheEnabledApiClient from '../CacheEnabledApiClient';

/* global axios */
class PhonesUbluxAPI extends CacheEnabledApiClient {
  constructor() {
    super('phones', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'phones';
  }

  // eslint-disable-next-line class-methods-use-this
  getFilterByUser(idUser) {
    return axios.ublux.post('/v1/Admin/Phone/GetMany', {
      idUser_eq: idUser,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  update(id, item) {
    return axios.ublux.patch(`/v1/Admin/Phone/Update/${id}`, {
      ...item,
    });
  }
}

export default new PhonesUbluxAPI();
