/* global axios */

import PortalsAPI from './portals';

class CategoriesAPI extends PortalsAPI {
  constructor() {
    super('categories', { accountScoped: true });
  }

  get({ portalSlug, locale }) {
    return axios.chatwoot.get(
      `${this.url}/${portalSlug}/categories?locale=${locale}`
    );
  }

  create({ portalSlug, categoryObj }) {
    return axios.chatwoot.post(
      `${this.url}/${portalSlug}/categories`,
      categoryObj
    );
  }

  update({ portalSlug, categoryId, categoryObj }) {
    return axios.chatwoot.patch(
      `${this.url}/${portalSlug}/categories/${categoryId}`,
      categoryObj
    );
  }

  delete({ portalSlug, categoryId }) {
    return axios.chatwoot.delete(
      `${this.url}/${portalSlug}/categories/${categoryId}`
    );
  }
}

export default new CategoriesAPI();
